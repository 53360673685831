import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './data/app-config.service';

const urlAPI = 'https://apilaravel.ml/api/auth/';

const httpOption = {
    headers: new HttpHeaders().set('Content-Type', 'application/json' )
};

@Injectable({providedIn: 'root'})
export class LoginService {

    private urlLogin: string;
    
    constructor(private http: HttpClient, private settings: AppConfigService) {
        this.urlLogin = this.settings.getUrlApi() + 'auth/';
    }

    getTokenLogin = (userData) => {
        return this.http.post(this.urlLogin+ 'login', JSON.stringify(userData), httpOption);              
    }

    async login(credentials) {
        let response = false;
        console.table("Credentials : ", credentials);
        if( credentials.email    === 'ego@go.com' && 
            credentials.password === 'uno') {
            response = true;
        }
        return {
            typeUser: 'superadmin',
            user: 'Adm',
            access: response
        }
    }
    
    registerUser = (data) => {
        const urlSignup = this.urlLogin + 'signup';

        return this.http.post(urlSignup, JSON.stringify(data), httpOption);

    }

    getUser = () => {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlLogin + 'user', httpOptions)
    }

    logOut() {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlLogin + 'logout', httpOptions)
    }

}