import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { Error404Module } from 'app/main/pages/errors/404/error-404.module';

import { MenuService } from "./providers/data/menu/menu.service";
import { LoginService } from "./providers/login.provider";
import { UsersProvider } from './providers/users.provider';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';

registerLocaleData(localeMx);


export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};
const appRoutes: Routes = [   

    // Not found
    { path: '**', redirectTo: 'home' },

    {
        path      : 'home',
        redirectTo: 'inicio'
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'users',
        loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule)
    },
    {
        path        : 'notifications',
        loadChildren: () => import('./main/notifications/notifications.module').then(m => m.NotificationsModule)
    },
    {
        path        : 'management', 
        loadChildren: () => import('./main/expedient/expedient.module').then(m => m.ExpedientModule)
    },
    {
        path        : 'demand',
        loadChildren: () => import('./main/demand/demand.module').then(m => m.DemandModule)
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        Error404Module,
    ],
    bootstrap   : [
        AppComponent
    ],
    providers :[ { provide: MAT_DATE_LOCALE, useValue: 'es-MX'}, { provide: LOCALE_ID, useValue: "es-MX" },] 

})
export class AppModule
{
    constructor(private _router:Router, 
                private _menuService: MenuService,
                private _userService: UsersProvider,
                private _loginservice: LoginService) {

        const tokenSession = sessionStorage.getItem('tk');
        if (tokenSession !== null && tokenSession !== undefined) {
            console.log('to home')
           // const info = this.auth.getDecodedAccessToken(tokenSession).data;
            this._loginservice.getUser().subscribe(
                (responseUser) => {
                    const typeUser = responseUser['data'].tipo;
                    if(typeUser === 'empleado'){
                        this._userService.getEmployeeData(responseUser['data'].empe).subscribe(
                            (response) => {
                                const typeEmployee = response['data'].trabdata.data.pues;
                                if( typeEmployee === 'Actuario'){
                                    this._menuService.setMenu(typeEmployee);
                                }
                                if( typeEmployee === 'Secretario de acuerdos'){
                                    this._menuService.setMenu(typeEmployee);
                                }
                            },
                            (error) => console.error(error)
                        );
                    }else {
                        this._menuService.setMenu(typeUser);
                    }
                },
                (error) => console.error(error)
            );
        }else {
            
            console.log('to login')
            this._router.navigate(['/pages/auth/login']);
        }

    }   
}
