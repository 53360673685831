import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './data/app-config.service';

@Injectable({providedIn: 'root'})
export class UsersProvider {
    private urlApi: string;
    
    constructor(private http: HttpClient, private settings: AppConfigService) {
        this.urlApi = this.settings.getUrlApi();
    }

    getAllCitizens = () => {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + 'citizens', httpOptions);              
    }
    
    getAllEmployees = () => {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + 'employees', httpOptions);              
    }

    getAllAutorities = () => {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + 'citizens', httpOptions);              
    }

    getUsersByType = (type) => {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + `users?tipo=${type}`, httpOptions);
    } 

    getUserByExpediente(params) {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + `registrations?stat=aceptado&une=${params.folio}&depa=${params.depa}`, httpOptions)
    }

    getAutoritiesByExpediente(id_notificacion) {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + `notifications/${id_notificacion}/authorities`, httpOptions)
    }

    getUserByAgreements(id_agreement) {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + `agreements/${ id_agreement }/citizens`, httpOptions)
    }

    getAutoritiesByAgreements(id_agreement) {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + `agreements/${ id_agreement }/authorities`, httpOptions)
    }

    getCitizenById = (iduser) => {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + `citizens/${ iduser }`, httpOptions);              
    }

    getUserByNum = (iduser) => {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + `users/${ iduser }`, httpOptions);              
    }

    getEmployeeData = (uid) => {
        const httpOptions = this.settings.getHeadersJson();
        return this.http.get(this.urlApi + `employees/${ uid }`, httpOptions);
    }

    uploadPhoto(user, dataUpload) {
        const httpOptions = this.settings.getHeadersMultipart();
        return this.http.post(this.urlApi + `users/${ user }/picture/`, dataUpload, httpOptions);
    }

    setTutorialComplete(id_user) { 
        const httpOptions = this.settings.getHeadersJson();
        return this.http.post(this.urlApi + `users/${ id_user }/tutorial`,JSON.stringify({ tuto: true}), httpOptions);
    }
}